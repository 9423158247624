exports.components = {
  "component---src-gatsby-templates-single-project-tsx": () => import("./../../../src/gatsby-templates/SingleProject.tsx" /* webpackChunkName: "component---src-gatsby-templates-single-project-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-baustelle-tsx": () => import("./../../../src/pages/baustelle.tsx" /* webpackChunkName: "component---src-pages-baustelle-tsx" */),
  "component---src-pages-datenschutz-mdx": () => import("./../../../src/pages/datenschutz.mdx" /* webpackChunkName: "component---src-pages-datenschutz-mdx" */),
  "component---src-pages-ideen-tsx": () => import("./../../../src/pages/ideen.tsx" /* webpackChunkName: "component---src-pages-ideen-tsx" */),
  "component---src-pages-impressum-mdx": () => import("./../../../src/pages/impressum.mdx" /* webpackChunkName: "component---src-pages-impressum-mdx" */),
  "component---src-pages-in-arbeit-tsx": () => import("./../../../src/pages/in-arbeit.tsx" /* webpackChunkName: "component---src-pages-in-arbeit-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-projekte-tsx": () => import("./../../../src/pages/projekte.tsx" /* webpackChunkName: "component---src-pages-projekte-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

